document.addEventListener("DOMContentLoaded", function(event) { 

    let submitBtn = document.getElementById("submit");
	let checkbox = document.getElementById("condition");

    if( submitBtn )
        submitBtn.disabled = true;

    if( checkbox ){
        checkbox.addEventListener('change', function() {
            if (this.checked) {
                // console.log("Checkbox is checked..");
                submitBtn.classList.remove("disabled");
                submitBtn.disabled = false;
            } else {
                // console.log("Checkbox is not checked..");
                submitBtn.classList.add("disabled");
                submitBtn.disabled = true;
            }
        });
    }

});

// Smooth scroll to anchor for Safari ...
if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    
    $("a.scroll").on("click", function (e) {
        e.preventDefault();
        const href = $(this).attr("href");
        $("html, body").animate({ scrollTop: $(href).offset().top }, 800);
    });

}

const swiper = new Swiper('.swiper', {

    loop: true,
    autoplay: {
        delay: 3000,
    },

});